import { createContext } from "react";
import DublaQueen1 from "./static/images/Dubla_Queen_1.jpg";
import DublaQueen2 from "./static/images/Dubla_Queen_2.jpg";
import DublaCuVedereLaPiscina1 from "./static/images/Dubla_Cu_Vedere_La_Piscina_1.jpg";
import DublaCuVedereLaPiscina2 from "./static/images/Dubla_Cu_Vedere_La_Piscina_2.jpg";
import DublaDeluxeCuBalcon1 from "./static/images/Dubla_Deluxe_Cu_Balcon_1.jpg";
import DublaDeluxeCuBalcon2 from "./static/images/Dubla_Deluxe_Cu_Balcon_2.jpg";
import SingleCuBalcon from "./static/images/Single_Cu_Balcon.jpg";
import TriplaDeluxeCuBalcon1 from "./static/images/Tripla_Deluxe_Cu_Balcon_1.jpg";
import Twin from "./static/images/Twin.jpg";
import Apartament_Cu_Balcon from "./static/images/Apartament_Cu_Balcon.jpg";
export const PageNameContext = createContext();
// export const api_url = "http://127.0.0.1:8000/backend/";
// export const api_url = "https://diamondhotel.md/backend/";
export const api_url = "/backend/";

export const rooms = [
  {
    name: "Double Queen 1",
    img: DublaQueen1,
    description:
      "This twin room's standout feature is the hot tub. The twin room has a private bathroom fitted with a shower and a hairdryer. The air-conditioned twin room offers a flat-screen TV with streaming services, a seating area, a wardrobe, a carpeted floor as well as city views. The unit has 2 beds.",
    features: [
      "Refrigerator",
      "Streaming service (like Netflix)",
      "Carpeted",
      "Flat-screen TV",
      "Single-room air conditioning for guest accommodation",
      "Heating",
      "Towels",
      "Wardrobe or closet",
      "Ironing facilities",
      "Seating Area",
      "Air conditioning",
      "Hand sanitiser",
      "Clothes rack",
      "Hot tub",
    ],
    views: ["Pool view", "City view"],
    smoking: false,
    size: 20,
  },
  {
    name: "Double Queen 2",
    img: DublaQueen2,
    description:
      "This twin room's standout feature is the hot tub. The twin room has a private bathroom fitted with a shower and a hairdryer. The air-conditioned twin room offers a flat-screen TV with streaming services, a seating area, a wardrobe, a carpeted floor as well as city views. The unit has 2 beds.",
    features: [
      "Refrigerator",
      "Streaming service (like Netflix)",
      "Carpeted",
      "Flat-screen TV",
      "Single-room air conditioning for guest accommodation",
      "Heating",
      "Towels",
      "Wardrobe or closet",
      "Ironing facilities",
      "Seating Area",
      "Air conditioning",
      "Hand sanitiser",
      "Clothes rack",
      "Hot tub",
    ],
    views: ["Pool view", "City view"],
    smoking: false,
    size: 20,
  },
  {
    name: "Double with pool view 1",
    img: DublaCuVedereLaPiscina1,
    description:
      "This double room provides a hot tub. Providing bathrobes, this double room includes a private bathroom with a shower, a hairdryer and slippers. The double room features carpeted floors, a seating area with a flat-screen TV, air conditioning, a wardrobe as well as pool views. The unit offers 1 bed.",
    features: [
      "Refrigerator",
      "Entire unit located on ground floor",
      "Carpeted",
      "Flat-screen TV",
      "Single-room air conditioning for guest accommodation",
      "Heating",
      "Towels",
      "Wardrobe or closet",
      "Ironing facilities",
      "Seating Area",
      "Air conditioning",
      "Hand sanitiser",
      "Clothes rack",
      "Hot tub",
    ],
    views: ["Pool view", "City view"],
    smoking: true,
    size: 20,
  },
  {
    name: "Double with pool view 2",
    img: DublaCuVedereLaPiscina2,
    description:
      "This double room provides a hot tub. Providing bathrobes, this double room includes a private bathroom with a shower, a hairdryer and slippers. The double room features carpeted floors, a seating area with a flat-screen TV, air conditioning, a wardrobe as well as pool views. The unit offers 1 bed.",
    features: [
      "Refrigerator",
      "Entire unit located on ground floor",
      "Carpeted",
      "Flat-screen TV",
      "Single-room air conditioning for guest accommodation",
      "Heating",
      "Towels",
      "Wardrobe or closet",
      "Ironing facilities",
      "Seating Area",
      "Air conditioning",
      "Hand sanitiser",
      "Clothes rack",
      "Hot tub",
    ],
    views: ["Pool view", "City view"],
    smoking: true,
    size: 20,
  },
  {
    name: "Double Deluxe with balcony 1",
    img: DublaDeluxeCuBalcon1,
    description:
      "Guests will have a special experience as this double room offers a hot tub. This air-conditioned double room includes a flat-screen TV with streaming services, a private bathroom as well as a balcony with pool views. The unit offers 1 bed.",
    features: [
      "Refrigerator",
      "Streaming service (like Netflix)",
      "Carpeted",
      "Flat-screen TV",
      "Sofa",
      "Single-room air conditioning for guest accommodation",
      "Heating",
      "Towels",
      "Wardrobe or closet",
      "Ironing facilities",
      "Seating Area",
      "Air conditioning",
      "Hand sanitiser",
      "Clothes rack",
      "Hot tub",
    ],
    views: ["Pool view", "City view", "Balcony"],
    smoking: true,
    size: 20,
  },
  {
    name: "Double Deluxe with balcony 2",
    img: DublaDeluxeCuBalcon2,
    description:
      "Guests will have a special experience as this double room offers a hot tub. This air-conditioned double room includes a flat-screen TV with streaming services, a private bathroom as well as a balcony with pool views. The unit offers 1 bed.",
    features: [
      "Refrigerator",
      "Streaming service (like Netflix)",
      "Carpeted",
      "Flat-screen TV",
      "Sofa",
      "Single-room air conditioning for guest accommodation",
      "Heating",
      "Towels",
      "Wardrobe or closet",
      "Ironing facilities",
      "Seating Area",
      "Air conditioning",
      "Hand sanitiser",
      "Clothes rack",
      "Hot tub",
    ],
    views: ["Pool view", "City view", "Balcony"],
    smoking: true,
    size: 20,
  },
  {
    name: "Single with balcony",
    img: SingleCuBalcon,
    description:
      "Guests will have a special experience as the single room provides a hot tub. This air-conditioned single room includes a flat-screen TV with streaming services, a private bathroom as well as a balcony with pool views. The unit offers 1 bed.",
    features: [
      "Refrigerator",
      "Streaming service (like Netflix)",
      "Carpeted",
      "Flat-screen TV",
      "Single-room air conditioning for guest accommodation",
      "Heating",
      "Towels",
      "Wardrobe or closet",
      "Ironing facilities",
      "Seating Area",
      "Air conditioning",
      "Hand sanitiser",
      "Clothes rack",
      "Hot tub",
    ],
    views: ["Pool view", "City view", "Balcony"],
    smoking: true,
    size: 15,
  },
  {
    name: "Triple Deluxe with balcony 1",
    img: TriplaDeluxeCuBalcon1,
    description:
      "Guests will have a special experience as this triple room features a hot tub. The triple room offers air conditioning, a seating area, a balcony with pool views as well as a private bathroom featuring a shower. The unit offers 2 beds.",
    features: [
      "Carpeted",
      "Flat-screen TV",
      "Sofa",
      "Sofa-bed",
      "Heating",
      "Towels",
      "Wardrobe or closet",
      "Ironing facilities",
      "Seating Area",
      "Air conditioning",
      "Clothes rack",
      "Hot tub",
    ],
    views: ["Pool view", "City view", "Balcony"],
    smoking: false,
    size: 25,
  },
  {
    name: "Deluxe Twin Room",
    img: Twin,
    description:
      "This twin room's standout feature is the hot tub. The twin room has a private bathroom fitted with a shower and a hairdryer. The air-conditioned twin room offers a flat-screen TV with streaming services, a seating area, a wardrobe, a carpeted floor as well as city views. The unit has 2 beds.",
    features: [
      "Refrigerator",
      "Streaming service (like Netflix)",
      "Carpeted",
      "Flat-screen TV",
      "Single-room air conditioning for guest accommodation",
      "Heating",
      "Towels",
      "Wardrobe or closet",
      "Ironing facilities",
      "Seating Area",
      "Air conditioning",
      "Hand sanitiser",
      "Clothes rack",
      "Hot tub",
    ],
    views: ["City view"],
    smoking: false,
    size: 20,
  },
  {
    name: "Apartment with Balcony",
    img: Apartament_Cu_Balcon,
    description:
      "This apartment features a hot tub and a fireplace. This air-conditioned apartment consists of 1 living room, 1 separate bedroom and 1 bathroom with a shower. The fully equipped kitchen features a stovetop, a refrigerator and an electric kettle. Featuring a balcony with pool views, this apartment also provides a mini-bar and a flat-screen TV with streaming services. The unit offers 2 beds.",
    features: [
      "Kitchen",
      "Refrigerator",
      "Minibar",
      "Electric kettle",
      "Stovetop",
      "Flat-screen TV",
      "Sofa",
      "Towels",
      "Ironing facilities",
      "Sitting area",
      "Streaming service (like Netflix)",
      "Fireplace",
      "Stovetop",
      "Carpeted",
      "Kitchenette",
      "Electric kettle",
      "Sofa bed",
      "Single room AC for guest accommodation",
      "Heating",
      "Wardrobe or closet",
      "Air conditioning",
      "Clothes rack",
      "Hot tub",
      "Hand sanitiser",
    ],
    views: ["Pool view", "City view", "Balcony"],
    smoking: true,
    size: 38,
  },
];
