import React, { useEffect } from "react";
import img_1 from "../static/images/slides/1.jpg";
import img_2 from "../static/images/slides/2.jpg";
import img_3 from "../static/images/slides/3.jpg";
import img_4 from "../static/images/slides/4.jpg";
import img_5 from "../static/images/slides/5.jpg";
import img_6 from "../static/images/slides/6.jpg";
import img_7 from "../static/images/slides/7.jpg";
import img_8 from "../static/images/slides/8.jpg";
import img_9 from "../static/images/slides/9.jpg";
import img_10 from "../static/images/slides/10.jpg";
import img_11 from "../static/images/slides/11.jpg";
import img_12 from "../static/images/slides/12.jpg";
import img_13 from "../static/images/slides/13.jpg";
import img_14 from "../static/images/slides/14.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

import "swiper/css";
import {
  Navigation,
  Pagination,
  A11y,
  Zoom,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/zoom";
import "swiper/css/autoplay";
import "swiper/css/scrollbar";
import Pharagraph from "../components/Pharagraph";
import InstagramIcon from "@mui/icons-material/Instagram";

const Section7 = () => {
  const pictures = [
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
    img_6,
    img_7,
    img_8,
    img_9,
    img_10,
    img_11,
    img_12,
    img_13,
    img_14,
  ];

  const [ref, inView] = useInView();
  const controls = useAnimation();
  const pharagraph_variants = {
    visible: {
      opacity: 1,
      translateY: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, translateY: 10 },
  };
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <section className="px-20 lg:px-12 md:px-8 sm:px-4 pt-20 text-white font-primary">
      
      <div className="flex gap-x-16 xl:gap-x-8 lg:gap-x-8 md:flex-col sm:flex-col">
        <div className="w-1/2 md:w-full xl:w-[60%] sm:w-full text-lg flex flex-col gap-y-4 tracking-wider">
          <h2 className="text-2xl font-medium">What is Diamond Hotel?</h2>
          <Pharagraph order={1}>
            Set in Chişinău, only 7 km away from KIV Airport, Diamond Hotel
            offers accommodation with a seasonal outdoor swimming pool, free
            private parking, a garden and a terrace. Offering a bar, the
            property is located within 8 km of Moldova State Philharmonic. Free
            WiFi and room service are offered.
          </Pharagraph>
          <Pharagraph order={2}>
            At the hotel, all rooms include a wardrobe. Complete with a private
            bathroom fitted with a shower and a hairdryer, all guest rooms at
            Diamond Hotel have a flat-screen TV and air conditioning, and
            certain rooms also offer a balcony. At the accommodation each room
            comes with a seating area.
          </Pharagraph>
          <Pharagraph order={3}>
            Speaking English, Spanish, Italian and Moldovan at the 24-hour front
            desk, staff are always at hand to help.
          </Pharagraph>
          <span className="xl:hidden lg:hidden">
            <Pharagraph order={4}>
              The Triumphal Arch Chisinau is 8.3 km from the hotel, while
              Chisinau Town Hall is 8.4 km from the property. The nearest
              airport is Chișinău International Airport, 8 km from Diamond
              Hotel.
            </Pharagraph>
          </span>
          <Pharagraph order={5}>
            <a href="https://www.instagram.com/diamondhotel.md/">
              <InstagramIcon style={{ fontSize: "3rem" }} />
            </a>
          </Pharagraph>
        </div>
        <div className="w-1/2 xl:w-[40%] md:w-full sm:w-full">
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={pharagraph_variants}
            className="mt-8"
          >
            <Swiper
              modules={[Pagination, A11y, Zoom, Autoplay, EffectFade]}
              spaceBetween={25}
              slidesPerView={1}
              autoplay={(true, { delay: 2000 })}
              loop={true}
              pagination={{ clickable: true }}
              fadeEffect={{ crossFade: true }}
              zoom={true}
            >
              {pictures.map((picture, index) => (
                <SwiperSlide key={index}>
                  <img src={picture} alt="slide" />
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Section7;
