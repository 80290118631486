import React, { useEffect, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { api_url } from "../constants";
import { Link, useNavigate } from "react-router-dom";

const Section4 = ({ rooms, room, indexSelected }) => {
  const [price, setPrice] = useState(room.price);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [isReserved, setIsReserved] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    if (checkIn == "" || checkOut == "") return;
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);
    const diffTime = Math.abs(checkOutDate - checkInDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setPrice(diffDays * room.price);
    setIsReserved(false);
    if (room.reservations.length == 0) return;
    room.reservations.map((reservation) => {
      const res_check_in = new Date(
        reservation.check_in.split("-")[0],
        reservation.check_in.split("-")[1] - 1,
        reservation.check_in.split("-")[2]
      );
      const res_check_out = new Date(
        reservation.check_out.split("-")[0],
        reservation.check_out.split("-")[1] - 1,
        reservation.check_out.split("-")[2]
      );
      const string_check_in = new Date(
        checkIn.split("-")[0],
        checkIn.split("-")[1] - 1,
        checkIn.split("-")[2]
      );
      const string_check_out = new Date(
        checkOut.split("-")[0],
        checkOut.split("-")[1] - 1,
        checkOut.split("-")[2]
      );
      if (
        (res_check_in >= string_check_in &&
          res_check_out <= string_check_out) ||
        (res_check_out >= string_check_in &&
          res_check_out <= string_check_out) ||
        (res_check_in >= string_check_in && res_check_in <= string_check_out) ||
        (res_check_in <= string_check_in &&
          res_check_out >= string_check_out) ||
        res_check_in == string_check_in ||
        res_check_out == string_check_out
      ) {
        setIsReserved(true);
      }
    });
  }, [checkIn, checkOut, room]);

  const SendTransactionHandler = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const name = data.get("name");
    const email = data.get("email");
    const phone = data.get("phone");
    const check_in = data.get("checkin");
    const check_out = data.get("checkout");
    const no_of_persons = data.get("persons");
    const room_type = rooms[indexSelected].id;
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);

    if (
      name == "" ||
      email == "" ||
      phone == "" ||
      check_in == "" ||
      check_out == "" ||
      no_of_persons == "" ||
      room_type == ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    if (checkInDate > checkOutDate) {
      alert("Check-in date must be before check-out date");
      return;
    }

    if (termsAccepted == false) {
      alert("Please accept the terms and conditions");
      return;
    }
    axios
      .post(api_url + "money-collect-payment", {
        name,
        email,
        phone,
        check_in,
        check_out,
        no_of_persons,
        room_type,
        price,
      })
      .then((res) => {
        console.log(res.data);
        const url = res.data;
        window.open(url, "_self");
      })
      .catch((e) => {
        console.log(e);
      });

    // axios
    //   .post(`${api_url}reservation`, {
    //     name,
    //     email,
    //     phone,
    //     check_in,
    //     check_out,
    //     no_of_persons,
    //     room_type,
    //     price,
    //   })
    //   .then((res) => {
    //     const transaction_id = res.data.TRANSACTION_ID;
    //     localStorage.setItem("transaction_id", transaction_id);
    //     const result_url =
    //       "https://maib.ecommerce.md:21443/ecomm/ClientHandler?trans_id=" +
    //       transaction_id;
    //     window.open(result_url, "_self");
    //   })
    //   .catch((err) => {
    //     alert("Something went wrong");
    //   });
  };
  return (
    <section className="px-20 lg:px-12 md:px-8 sm:px-4">
      <div className=" mt-4 flex gap-x-12 md:gap-y-8 md:flex-col sm:flex-col sm:gap-y-8">
        <form
          id="email-form"
          onSubmit={SendTransactionHandler}
          className="w-1/2 md:w-full grid-cols-3 grid gap-4 sm:grid-cols-1 sm:w-full lg:grid-cols-2 md:grid-cols-2 md:order-2 sm:order-2"
        >
          <div>
            <label className="text-white font-medium text-sm" for="name">
              Full Name
            </label>
            <input name={"name"} placeholder="John Doe" id="name" />
          </div>
          <div>
            <label className="text-white font-medium text-sm" for="email">
              Email Address
            </label>
            <input
              name={"email"}
              placeholder="example@gmail.com"
              type={"email"}
              id="email"
            />
          </div>
          <div>
            <label className="text-white font-medium text-sm" for="phone">
              Your Phone
            </label>
            <input
              name={"phone"}
              placeholder="+44 1423 396979"
              type={"tel"}
              id="phone"
            />
          </div>
          <div>
            <label className="text-white font-medium text-sm" for="checkin">
              Check-in
            </label>
            <input
              value={checkIn}
              min={new Date().toISOString().split("T")[0]}
              onChange={(e) => setCheckIn(e.target.value)}
              name={"checkin"}
              placeholder="Check-in"
              type={"date"}
              id="checkin"
            />
          </div>
          <div>
            <label className="text-white font-medium text-sm" for="checkout">
              Check-out
            </label>
            <input
              min={new Date().toISOString().split("T")[0]}
              value={checkOut}
              onChange={(e) => setCheckOut(e.target.value)}
              name={"checkout"}
              placeholder="Check-out"
              type={"date"}
              id="checkout"
            />
          </div>
          <div>
            <label className="text-white font-medium text-sm" for="persons">
              No. of Persons
            </label>

            <input
              className="hover:appearance-none	focus:appearance-none	 !appearance-none"
              name={"persons"}
              placeholder=">0"
              type={"number"}
              id="persons"
            />
          </div>
          <button
            disabled={isReserved}
            className="group lg:col-span-2 md:col-span-2 sm:col-span-1 col-span-3 h-fit mt-auto font-medium font-primary uppercase relative bg-transparent py-4 text-center border-solid border-black border-4 w-full px-4 text-white/70 hover:text-white transition-colors ease-in-out"
          >
            <span className="">
              {isReserved
                ? "Already booked. Choose another room or date."
                : "Book a room"}
            </span>
            <div className="w-0 h-full left-0 bottom-0 bg-black transition-all ease-in-out duration-500 group-hover:w-full absolute"></div>

            <span className="top-1/2 left-1/2 translate-x-[-50%] text-white translate-y-[-50%] absolute whitespace-nowrap">
              {isReserved
                ? "Already booked. Choose another room or date."
                : "Book a room"}
            </span>
          </button>
          <div className="lg:col-span-2 md:col-span-2 sm:col-span-1 col-span-3 text-white flex w-fit items-center ml-auto gap-x-3">
            <label for="accept">
              I have read and accept the applied{" "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="/terms-and-conditions"
                className="underline underline-offset-2	"
              >
                Terms and Conditions
              </Link>
            </label>
            <input
              onChange={(e) => setTermsAccepted(e.target.checked)}
              name={"accept"}
              value={termsAccepted}
              type="checkbox"
              id="accept"
              className="w-5 h-5 checked:bg-gold cursor-pointer "
            />
          </div>
          <p
            className={
              "lg:col-span-2 md:col-span-2 sm:col-span-1 col-span-3 text-white font-primary text-xl"
            }
          >
            <span className="font-semibold">Price: </span>
            {price} €
          </p>
        </form>

        <div className="w-1/2 text-white/70 flex flex-col gap-y-8 md:order-1 md:w-full sm:w-full sm:order-1">
          <p>
            <span className="text-white font-medium">Room size:</span>{" "}
            {room.size} m<sup>2</sup>
          </p>
          <p className="">
            <span className="text-white font-medium">Description:</span>{" "}
            <div className="flex-col gap-y-2 flex">
              {room.description &&
                room.description.split("\n").map((i) => <p>{i}</p>)}
            </div>
          </p>
          <p className="flex gap-x-4">
            <span className="text-white font-medium">Views:</span>{" "}
            {room.views.map((view) => (
              <div className="text-sm h-fit">
                <TaskAltIcon className="text-white" />
                {view.name}
              </div>
            ))}
          </p>
          <p>
            <span className="text-white font-medium">Features:</span>{" "}
            <div className="grid grid-cols-3 lg:grid-cols-2 sm:grid-cols-2">
              {room.features.map((feature) => (
                <div className="text-sm h-fit">
                  <CheckIcon className="text-white" />
                  {feature.name}
                </div>
              ))}
            </div>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Section4;
