import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import { Link } from "react-router-dom";
import { PageNameContext } from "../constants";
const ManualReservations = () => {
  const [rooms, setRooms] = useState([]);
  const pageName = useContext(PageNameContext);
  useEffect(() => {
    pageName.set("Manual Reservations");
    document.title = "Chisinau's Diamond Hotel";
    const code = localStorage.getItem("code");
    axios
      .get(api_url + "admin-rooms", {
        params: {
          code: code,
        },
      })
      .then((res) => {
        const res_rooms = res.data;
        res_rooms.map((room) => {
          room.shown_reservations = 5;
        });
        setRooms(res_rooms);
        console.log(res_rooms);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-6 px-16 lg:px-12 md:px-8 sm:px-4 pt-4 text-white font-primary pb-12 mt-12">
      {rooms.length > 0 &&
        rooms.map((room, index) => (
          <Link to={"/admin/manual-reservations/" + room.id}>
            <motion.div
              className="flex flex-col w-full cursor-pointer"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: (index + 1) * 0.1 }}
            >
              <div className="relative group overflow-auto scrollbar-none">
                <img
                  className="w-full group-hover:scale-105 transition-transform ease-in-out duration-300"
                  src={api_url.replace("/backend/", "") + room.img}
                />
                <div className="group-hover:bg-black/25 absolute top-0 left-0 right-0 bottom-0 transition-colors ease-in-out duration-300"></div>
              </div>
              <h2 className="text-lg font-medium text-white mt-2">
                {room.name} ({room.size} m<sup>2</sup>)
              </h2>
            </motion.div>
          </Link>
        ))}
    </div>
  );
};

export default ManualReservations;
