import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../constants";
import { motion } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";
import check from "../static/images/check.svg";
import LoadingSpinner from "../components/LoadingSpinner";
import x from "../static/images/x.svg";

const SuccessfulMoneyCollectTransaction = () => {
  const [searchParams] = useSearchParams();
  const payment_client_secret = searchParams.get("payment_client_secret");
  const payment_id = searchParams.get("payment_id");
  const source_redirect_slug = searchParams.get("source_redirect_slug");
  const [isLoading, setIsLoading] = useState(true);

  const [transaction, setTransaction] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (payment_client_secret && payment_id && source_redirect_slug) {
      axios
        .post(`${api_url}money-collect-payment/success`, {
          payment_client_secret,
          payment_id,
          source_redirect_slug,
        })
        .then((res) => {
          console.log(res.data);
          setTransaction(res.data);
          setIsLoading(false);
          setIsSuccess(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          navigate("/");
          setTransaction({});
          setIsSuccess(false);
        });
    } else {
      navigate("/");
    }
  }, []);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col w-1/3 lg:w-1/2 md:w-2/3 sm:w-full sm:px-4 mx-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-65%]"
    >
      <img
        src={transaction.status !== "OK" ? x : check}
        className="w-16 mx-auto"
      />
      <h1
        className={
          "font-semibold mt-4 uppercase mx-auto w-fit sm:text-sm" +
          (transaction.status !== "OK" ? " text-[#F20001]" : " text-[#40C1AC]")
        }
      >
        {(transaction.status == "OK" && "The transaction was successful!") ||
          (transaction.status == "REFUNDED" &&
            "The transaction was refunded!") ||
          "The transaction was not successful!"}
      </h1>
      <div className="flex justify-between text-white mt-12">
        <div className="flex flex-col font-semibold sm:text-sm">
          <div>Merchant:</div>
          <div>Website:</div>
          <div>Price:</div>
          <div>Date:</div>
          <div>Transaction ID:</div>
          <div>Description:</div>
        </div>
        <div className="flex flex-col text-right sm:text-sm">
          <div>ONEDIAMOND S.R.L</div>
          <div>diamondhotel.md</div>
          <div>{transaction.price} EUR</div>
          <div>{transaction.created_at}</div>
          <div>{transaction.transaction_id}</div>
          <div>{transaction.description}</div>
        </div>
      </div>
      {transaction.status === "OK" && (
        <p className="mt-4 text-xs text-white font-medium">
          Check your email for the tickets: {transaction.email}
        </p>
      )}
      <Link
        to="/"
        className="bg-[#40C1AC] sm:text-sm w-fit px-4 py-1 font-medium text-white mt-2 uppercase"
      >
        Back to home page
      </Link>
    </motion.div>
  );
};

export default SuccessfulMoneyCollectTransaction;
