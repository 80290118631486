import React, { createContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import TermsAndConditions from "./screens/TermsAndConditions";
import AdminReservations from "./screens/AdminReservations";
import SuccessfulTransaction from "./screens/SuccessfulTransaction";
import AdminLayout from "./screens/AdminLayout";
import ReverseTransaction from "./screens/ReverseTransaction";
import ManualReservations from "./screens/ManualReservations";
import EditManualReservation from "./screens/EditManualReservation";
import AdminRooms from "./screens/AdminRooms";
import AdminRoomNew from "./screens/AdminRoomNew";
import AdminRoom from "./screens/AdminRoom";
import AdminRoomEdit from "./screens/AdminRoomEdit";
import AdminPromotions from "./screens/AdminPromotions";
import AdminAddPromotion from "./screens/AdminAddPromotion";
import { PageNameContext } from "./constants";
import SuccessfulMoneyCollectTransaction from "./screens/SuccessfulMoneyCollectTransaction";
import ReverseMoneyCollectTransaction from "./screens/ReverseMoneyCollectTransaction";
function App() {
  const [pageName, setPageName] = useState("");
  return (
    <div>
      <PageNameContext.Provider value={{ get: pageName, set: setPageName }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="/admin/reservations" element={<AdminReservations />} />
            <Route
              path="/admin/manual-reservations"
              element={<ManualReservations />}
            />
            <Route
              path="/admin/manual-reservations/:id"
              element={<EditManualReservation />}
            />
            <Route path="/admin/rooms" element={<AdminRooms />} />
            <Route path="/admin/rooms/new" element={<AdminRoomNew />} />
            <Route path="/admin/rooms/:id" element={<AdminRoom />} />
            <Route path="/admin/rooms/:id/edit" element={<AdminRoomEdit />} />
            <Route path="/admin/promotions" element={<AdminPromotions />} />
            <Route
              path="/admin/promotions/add"
              element={<AdminAddPromotion />}
            />
          </Route>
          <Route path="/api/" element={<SuccessfulTransaction />} />
          <Route
            path="/success"
            element={<SuccessfulMoneyCollectTransaction />}
          />
          <Route path="/reverse" element={<ReverseMoneyCollectTransaction />} />
          <Route
            path="/api/reverse/:transaction_id"
            element={<ReverseTransaction />}
          />
        </Routes>
      </PageNameContext.Provider>
    </div>
  );
}

export default App;
