import React, { useState } from "react";
import swimming_pool from "../static/images/swimming-pool.jpg";
import reception from "../static/images/reception.jpg";
import food from "../static/images/food.png";
import wifi_icon from "../static/images/wifi-icon.svg";
import free_parking_icon from "../static/images/free-parking-icon.svg";
import family_rooms_icon from "../static/images/family-rooms-icon.svg";
import bar_icon from "../static/images/bar-icon.svg";
import room_service_icon from "../static/images/room-service-icon.svg";
import non_smoking_icon from "../static/images/non-smoking-icon.svg";
import Dialog from "../components/Dialog";
import Banner from "../components/Banner";

const Section2 = () => {
  const [showDialog, setShowDialog] = useState(0);
  return (
    <section className=" px-20 lg:px-12 md:px-8 sm:px-4 snap-proximity snap-always snap-end scroll-py-52 pt-8 snap-proximity snap-always snap-start scroll-py-52">
      <p className="text-white font-primary text-center text-2xl mx-auto font-medium">
        Designers solutions will impress you greatly. Each and every trifle is
        here for your comfort.
      </p>
      <div className="flex gap-x-8 sm:flex-col sm:gap-y-6 justify-between w-full transition-all ease-in-out duration-200 mt-12">
        <Dialog
          key={1}
          index={1}
          img={wifi_icon}
          h2={"wifi"}
          p={
            "Seamless connectivity in an impeccable setting. Enjoy complimentary high-speed Wi-Fi throughout our luxury hotel."
          }
          dialogSetter={setShowDialog}
          dialog={showDialog}
        />
        <Dialog
          key={2}
          index={2}
          img={free_parking_icon}
          h2={"Free parking"}
          p={
            "Enjoy the convenience of complimentary valet parking during your stay."
          }
          dialogSetter={setShowDialog}
          dialog={showDialog}
        />
        <Dialog
          key={3}
          index={3}
          img={family_rooms_icon}
          h2={"Family rooms"}
          p={
            "We provide spacious family rooms, designed specifically to ensure your comfort and relaxation during your stay at our hotel. "
          }
          dialogSetter={setShowDialog}
          dialog={showDialog}
        />
        <Dialog
          key={4}
          index={4}
          img={bar_icon}
          h2={"Bar & Restaurant"}
          p={
            "Enjoy exceptional experiences at our bar, where you'll discover fine drinks and an elegant ambiance, perfect for moments of relaxation and socializing."
          }
          dialogSetter={setShowDialog}
          dialog={showDialog}
        />
        <Dialog
          key={5}
          index={5}
          img={room_service_icon}
          h2={"Room service"}
          p={
            "Enjoy our room service, bringing the refinement of our restaurant to the privacy and comfort of your room."
          }
          dialogSetter={setShowDialog}
          dialog={showDialog}
        />
        <Dialog
          key={6}
          index={6}
          img={non_smoking_icon}
          h2={"Non-smoking rooms"}
          p={
            "We provide specially designated non-smoking rooms to offer you a clean and fresh environment during your stay at our hotel."
          }
          dialogSetter={setShowDialog}
          dialog={showDialog}
        />
      </div>
      <div className="grid grid-cols-3 sm:grid-cols-1 sm:gap-y-8 justify-between gap-x-20 lg:gap-x-12 md:gap-x-6 mt-12">
        <Banner
          order={1}
          h={"Swimming Pool"}
          p={
            "Sun-soaked relaxation. Enjoy refinement and comfort at ouroutdoor pool."
          }
          img={swimming_pool}
        />

        <Banner
          order={2}
          h={"24-hour front desk"}
          p={
            "24/7 assistance at our front desk, ensuring personalized service whenever you need it."
          }
          img={reception}
        />
        <Banner
          order={3}
          h={"Buffet breakfast"}
          p={
            "Savour exquisite cuisine at our restaurant, with a remarkable dining experience."
          }
          img={food}
        />
      </div>
    </section>
  );
};

export default Section2;
