import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../constants";
import { useEffect, useState } from "react";
import refund from "../static/images/refund.svg";
import { Link } from "react-router-dom";
import x from "../static/images/x.svg";
import LoadingSpinner from "../components/LoadingSpinner";
import { motion } from "framer-motion";

const ReverseTransaction = () => {
  const { transaction_id } = useParams();
  const [transactionInfo, setTransactionInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${api_url}reservation`, {
        params: {
          transaction_id: transaction_id,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTransactionInfo(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function refundTransaction() {
    setIsLoading(true);
    axios
      .post(`${api_url}reverse-transaction`, {
        transaction_id: transaction_id,
      })
      .then((res) => {
        console.log(res.data);
        setTransactionInfo(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col w-1/3 lg:w-1/2 md:w-2/3 sm:w-full sm:px-4 mx-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-65%]"
    >
      <img
        src={
          transactionInfo.status !== "OK" ||
          transactionInfo.reverse_elibiligty !== true
            ? x
            : refund
        }
        className="w-16 mx-auto"
      />
      <h1
        className={
          "w-fit mx-auto mt-4 mx-auto w-fit sm:text-sm" +
          (transactionInfo.status !== "OK" ||
          transactionInfo.reverse_elibiligty !== true
            ? " text-[#F20001]"
            : " text-[#40C1AC]")
        }
      >
        Transaction <b>{transactionInfo.transaction_id}</b>{" "}
        {(transactionInfo.status == "OK" &&
          transactionInfo.reverse_elibiligty == true &&
          "is eligible for refund!") ||
          (transactionInfo.status == "REFUNDED" && "was refunded!") ||
          "is not eligible for refund!"}
      </h1>
      <div className="flex justify-between text-white mt-10">
        <div className="flex flex-col font-semibold sm:text-sm">
          <div>Merchant:</div>
          <div>Website:</div>
          <div>Price:</div>
          <div>Date:</div>
          <div>Transaction ID:</div>
          <div>Description:</div>
        </div>
        <div className="flex flex-col text-right sm:text-sm">
          <div>ONEDIAMOND S.R.L</div>
          <div>diamondhotel.md</div>
          <div>{transactionInfo.price} EUR</div>
          <div>{transactionInfo.created_at}</div>
          <div>{transactionInfo.transaction_id}</div>
          <div>{transactionInfo.description}</div>
        </div>
      </div>
      {(transactionInfo.status == "OK" &&
        transactionInfo.reverse_elibiligty == true && (
          <button
            className="bg-[#40C1AC] sm:text-sm w-fit px-4 py-1 font-medium text-white mt-2 uppercase"
            onClick={refundTransaction}
          >
            Refund transaction
          </button>
        )) || (
        <Link
          to="/"
          className="bg-[#40C1AC] sm:text-sm w-fit px-4 py-1 font-medium text-white mt-2 uppercase"
        >
          Back to home page
        </Link>
      )}
    </motion.div>
  );
};

export default ReverseTransaction;
