import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import { Link } from "react-router-dom";
import axios from "axios";
import { PageNameContext } from "../constants";
import LoadingSpinner from "../components/LoadingSpinner";

const AdminRooms = () => {
  const [rooms, setRooms] = useState([]);
  const pageName = useContext(PageNameContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    pageName.set("Rooms");
    setLoading(true);
    document.title = "Chisinau's Diamond Hotel";
    const code = localStorage.getItem("code");
    axios
      .get(api_url + "admin-rooms", {
        params: {
          code: code,
        },
      })
      .then((res) => {
        const res_rooms = res.data;
        setRooms(res_rooms);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="">
      <div className="mt-10 px-8">
        <Link
          to="/admin/rooms/new"
          className="bg-white px-4 py-1.5 font-medium"
        >
          Add new room
        </Link>
      </div>
      <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-6 px-8 lg:px-12 md:px-8 sm:px-4 pt-4 text-white font-primary pb-12 mt-8">
        {rooms.length > 0 &&
          rooms.map((room, index) => (
            <Link to={"/admin/rooms/" + room.id}>
              <motion.div
                className="flex flex-col w-full cursor-pointer"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: (index + 1) * 0.1 }}
              >
                <div className="relative group overflow-auto scrollbar-none">
                  <img
                    className="w-full group-hover:scale-105 transition-transform ease-in-out duration-300"
                    src={api_url.replace("/backend/", "") + room.img}
                  />
                  <div className="group-hover:bg-black/25 absolute top-0 left-0 right-0 bottom-0 transition-colors ease-in-out duration-300"></div>
                </div>
                <h2 className="text-lg font-medium text-white mt-2">
                  {room.name} ({room.size} m<sup>2</sup>)
                </h2>
              </motion.div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default AdminRooms;
