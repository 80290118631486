import React from "react";
import { useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { api_url } from "../constants";
import LoadingSpinner from "../components/LoadingSpinner";
import x from "../static/images/x.svg";
import refund from "../static/images/refund.svg";

const ReverseMoneyCollectTransaction = () => {
  const [searchParams] = useSearchParams();
  const payment_client_secret = searchParams.get("payment_client_secret");
  const payment_id = searchParams.get("payment_id");
  const source_redirect_slug = searchParams.get("source_redirect_slug");
  const [transaction, setTransaction] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${api_url}money-collect-payment/${payment_id}`)
      .then((res) => {
        setIsLoading(false);
        setTransaction(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function refundTransaction() {
    setIsLoading(true);
    axios
      .post(`${api_url}money-collect-payment/cancel`, {
        payment_id: payment_id,
        payment_client_secret: payment_client_secret,
        source_redirect_slug: source_redirect_slug,
      })
      .then((res) => {
        console.log(res.data);
        setTransaction(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setError(true);
      });
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col w-1/3 lg:w-1/2 md:w-2/3 sm:w-full sm:px-4 mx-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-65%]"
    >
      <img
        src={
          transaction.status !== "OK" || transaction.reverse_elibiligty !== true
            ? x
            : refund
        }
        className="w-16 mx-auto"
      />
      <h1
        className={
          "w-fit mx-auto mt-4 mx-auto w-fit sm:text-sm" +
          (transaction.status !== "OK" ||
          transaction.reverse_elibiligty !== true ||
          error
            ? " text-[#F20001]"
            : " text-[#40C1AC]")
        }
      >
        Transaction <b>{transaction.transaction_id}</b>{" "}
        {(error && "was not refunded! Contact our email for support.") ||
          (transaction.status == "OK" &&
            transaction.reverse_elibiligty == true &&
            "is eligible for refund!") ||
          (transaction.status == "REFUNDED" && "was refunded!") ||
          "is not eligible for refund!"}
        {/* {error && <Link to=""} */}
      </h1>
      <div className="flex justify-between text-white mt-10">
        <div className="flex flex-col font-semibold sm:text-sm">
          <div>Merchant:</div>
          <div>Website:</div>
          <div>Price:</div>
          <div>Date:</div>
          <div>Transaction ID:</div>
          <div>Description:</div>
        </div>
        <div className="flex flex-col text-right sm:text-sm">
          <div>ONEDIAMOND S.R.L</div>
          <div>diamondhotel.md</div>
          <div>{transaction.price} EUR</div>
          <div>{transaction.created_at}</div>
          <div>{transaction.transaction_id}</div>
          <div>{transaction.description}</div>
        </div>
      </div>
      {(transaction.status == "OK" &&
        transaction.reverse_elibiligty == true && (
          <button
            className="bg-[#40C1AC] sm:text-sm w-fit px-4 py-1 font-medium text-white mt-2 uppercase"
            onClick={refundTransaction}
          >
            Refund transaction
          </button>
        )) || (
        <Link
          to="/"
          className="bg-[#40C1AC] sm:text-sm w-fit px-4 py-1 font-medium text-white mt-2 uppercase"
        >
          Back to home page
        </Link>
      )}
    </motion.div>
  );
};

export default ReverseMoneyCollectTransaction;
