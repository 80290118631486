import React, { useState } from "react";
import RoomSelect from "../components/RoomSelect";

const Section3 = ({ rooms, roomSelected, setRoomSelected }) => {
  return (
    <section className="px-20 lg:px-12 md:px-8 sm:px-4 pt-8 snap-proximity snap-always snap-start scroll-py-52">
      <p className=" text-white pt-8 text-center font-primary sm:text-lg sm:text-center text-2xl md:text-xl ml-auto font-medium">
        There are a lot of hotels in the world, but only a few of them are truly
        luxurious. <br />
        Diamond Hotel is one of them. Choose one of our rooms and enjoy your
        stay.
      </p>
      <div className="grid grid-cols-3 gap-4 mt-6 md:grid-cols-2 sm:grid-cols-1">
        {rooms.length > 0 &&
          rooms.map((room, index) => (
            <RoomSelect
              room={roomSelected}
              roomSelector={setRoomSelected}
              img={room.img}
              name={room.name}
              index={index}
            />
          ))}
      </div>
    </section>
  );
};

export default Section3;
