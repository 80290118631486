import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import confirm from "../static/images/confirm.svg";
import axios from "axios";
import confirm_white from "../static/images/confirm-white.svg";
import remove from "../static/images/remove.svg";
import remove_white from "../static/images/remove-white.svg";
const PromotionCard = ({ promotion, setPromotions, promotions }) => {
  const [isActive, setIsActive] = useState(promotion.is_active);

  useEffect(() => {
    setIsActive(promotion.is_active);
  }, [promotion.is_active]);

  function toggleActive() {
    const code = localStorage.getItem("code");

    axios
      .put(api_url + "promotion/" + promotion.id, {
        code: code,
        is_active: !isActive,
      })
      .then((res) => {
        setIsActive(!isActive);
        //change the promotions is_active state to false except the one that was clicked only if the promotion was set to active
        if (!isActive) {
          setPromotions(
            promotions.map((res_promotion) => {
              if (res_promotion.id != promotion.id) {
                res_promotion.is_active = false;
              }
              return res_promotion;
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function DeletePromotion() {
    // show an confirmation alert
    if (!window.confirm("Are you sure you want to delete this promotion?"))
      return;
    const code = localStorage.getItem("code");
    axios
      .delete(api_url + "promotion/" + promotion.id, {
        params: {
          code: code,
        },
      })
      .then((res) => {
        setPromotions(
          promotions.filter((res_promotion) => res_promotion.id != promotion.id)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white/70 rounded-lg shadow-lg p-4 mt-4"
    >
      <div className="flex justify-between items-center md:flex-col sm:flex-col">
        <h2 className="text-lg font-semibold">{promotion.name}</h2>
        <div className="font-semibold text-sm">{promotion.room.name}</div>
      </div>
      <motion.div className="flex mt-2 gap-x-2 sm:flex-col gap-y-4 sm:mt-4">
        <div className="relative w-1/4 group sm:w-full">
          <img
            src={api_url.replace("backend/", "") + promotion.room.img}
            className="w-full"
          />
          <div className="group-hover:bg-black/30 transition-opacity ease-in-out duration-300  bg-transparent absolute top-0 left-0 right-0 bottom-0"></div>
          <img
            onClick={DeletePromotion}
            src={remove_white}
            className="w-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer transition-opacity ease-in-out duration-300 group-hover:opacity-100 opacity-0"
          />
        </div>
        <div className="text-sm font-medium flex w-full flex-col">
          {promotion.description &&
            promotion.description
              .split("\n")
              .map((line) => <p className="">{line}</p>)}
          <div
            className="cursor-pointer w-fit items-center flex gap-x-2 mt-auto ml-auto"
            onClick={toggleActive}
          >
            <span className="text-white font-semibold">
              {isActive ? "Is active" : "Is not active"}
            </span>
            <div className="w-6 h-6 bg-black relative">
              <img
                src={confirm_white}
                className={
                  "w-4 h-4 absolute left-1/2 transition-opacity ease-in-out duration-300 top-1/2 -translate-x-1/2 -translate-y-1/2" +
                  (isActive ? " opacity-100" : " opacity-0")
                }
              />
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default PromotionCard;
