import React from "react";
import { LineWave } from "react-loader-spinner";
import { motion } from "framer-motion";
const LoadingSpinner = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]"
    >
      <LineWave width={100} height={100} color="#FFFFFF" />
    </motion.div>
  );
};

export default LoadingSpinner;
