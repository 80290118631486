import React, { useState } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { useNavigate, Link } from "react-router-dom";
import ImageCropModal from "../components/ImageCropModal";
import add from "../static/images/add.svg";
import change from "../static/images/change.svg";
import confirm from "../static/images/confirm.svg";
import remove from "../static/images/remove.svg";
const AdminRoomNew = () => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);
  const [isSmokingAllowed, setIsSmokingAllowed] = useState(false);
  const navigate = useNavigate();
  const [features, setFeatures] = useState([]);
  const [views, setViews] = useState([]);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFileType(file.type);
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  function AddRoom(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("img", resultFile);
    formData.append("img_type", fileType.split("/")[1]);
    formData.append("code", localStorage.getItem("code"));
    formData.append("smoking", isSmokingAllowed);
    formData.append("features", JSON.stringify(features));
    formData.append("views", JSON.stringify(views));
    axios
      .post(api_url + "room", formData)
      .then((res) => {
        console.log(res.data);
        navigate("/admin/rooms");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addFeature(e) {
    e.preventDefault();
    const new_feature = e.target.new_feature.value;
    setFeatures([...features, new_feature]);
    e.target.reset();
  }

  function addView(e) {
    e.preventDefault();
    const new_view = e.target.new_view.value;
    setViews([...views, new_view]);
    e.target.reset();
  }

  function removeFeature(index) {
    setFeatures(features.filter((feature, i) => i != index));
  }

  function removeView(index) {
    setViews(views.filter((view, i) => i != index));
  }

  return (
    <div className=" px-8 mt-10">
      <h2 className="text-white font-semibold text-xl">Basic Informations</h2>
      <form
        onSubmit={AddRoom}
        className="mt-2 md:flex-col gap-y-4 relative flex-wrap flex w-full gap-x-4"
      >
        {resultImage ? (
          <label
            htmlFor="product-graphics"
            className="w-72 sm:order-2 block relative text-white bg-backgroundGray group cursor-pointer"
          >
            <img src={resultImage} className="w-full" />
            <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
            <span className=" text-white absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150">
              <img src={change} className="w-4" />
            </span>
            <input
              ref={file}
              onChange={selectFile}
              id="product-graphics"
              type="file"
              className="hidden"
            />
          </label>
        ) : (
          <label className="h-60 sm:order-2 block relative w-72 bg-white/75 group cursor-pointer">
            <span className="group-hover:scale-150 block ease-in-out duration-300 transition-all absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2  scale-125">
              <img src={add} className="w-4" />
            </span>
            <input
              ref={file}
              onChange={selectFile}
              type="file"
              className="hidden"
            />
          </label>
        )}
        <div className="w-72 sm:order-3 flex flex-col gap-y-4">
          <input
            name="name"
            className="admin-input w-full"
            placeholder="Room name"
          />
          <textarea
            className="admin-input w-full min-h-[11rem]"
            placeholder="Description"
            name="description"
          ></textarea>
        </div>
        <div className="w-72 sm:order-4 flex flex-col gap-y-4">
          <input
            name="price"
            className="admin-input w-full"
            placeholder="Price"
          />
          <input
            name="size"
            className="admin-input w-full"
            placeholder="Size"
          />
          <div
            className="cursor-pointer w-fit flex gap-x-2"
            onClick={() => setIsSmokingAllowed(!isSmokingAllowed)}
          >
            <div className="w-6 h-6 bg-white/60 relative">
              <img
                src={confirm}
                className={
                  "w-4 h-4 absolute left-1/2 transition-opacity ease-in-out duration-300 top-1/2 -translate-x-1/2 -translate-y-1/2" +
                  (isSmokingAllowed ? " opacity-100" : " opacity-0")
                }
              />
            </div>
            <span className="text-white font-semibold">
              {isSmokingAllowed ? "Smoking allowed" : "Smoking not allowed"}
            </span>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between sm:w-full sm:order-1 md:absolute md:top-0 md:right-0 lg:mt-6 gap-y-4 w-fit ml-auto">
          <Link
            to="/admin/rooms"
            className="admin-alert-button px-8 text-center"
          >
            Cancel form
          </Link>
          <button className="bg-white/60 font-bold py-1  px-8 text-center">
            Add new room
          </button>
        </div>
      </form>
      <div className="grid grid-cols-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 mt-8 gap-x-4">
        <form onSubmit={addFeature} className="">
          <h2 className="text-white  font-semibold text-xl">Features</h2>
          <div className="mt-2">
            <input name="new_feature" className="" placeholder="Feature Name" />
            <button className="bg-white/60 px-4 font-semibold ml-auto mt-2 block">
              ADD FEATURE
            </button>
          </div>
          <div className="flex flex-col gap-y-2 font-semibold mt-4">
            {features.map((feature, index) => (
              <div className="bg-white/60 py-1 px-2 w-full flex justify-between group">
                {feature}
                <img
                  src={remove}
                  onClick={() => removeFeature(index)}
                  className="group-hover:opacity-100 opacity-0 transition-opacity cursor-pointer ease-in-out duration-300 w-4"
                />
              </div>
            ))}
          </div>
        </form>
        <form onSubmit={addView} className="">
          <h2 className="text-white font-semibold text-xl">Views</h2>
          <div className="mt-2">
            <input name="new_view" className="" placeholder="Feature Name" />
            <button className="bg-white/60 px-4 font-semibold ml-auto mt-2 block">
              ADD VIEW
            </button>
          </div>
          <div className="flex flex-col gap-y-2 font-semibold mt-4">
            {views.map((view, index) => (
              <div className="group bg-white/60 py-1 px-2 w-full flex justify-between">
                {view}
                <img
                  src={remove}
                  onClick={() => removeView(index)}
                  className="group-hover:opacity-100 opacity-0 transition-opacity cursor-pointer ease-in-out duration-300 w-4"
                />
              </div>
            ))}
          </div>
        </form>
        <div></div>
      </div>
      <ImageCropModal
        aspectRatio={4 / 3}
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
      />
    </div>
  );
};

export default AdminRoomNew;
