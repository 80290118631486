import React from "react";
import DiamondEmpty from "../static/images/diamond-empty.svg";
import DiamondFilled from "../static/images/diamond-filled.svg";
const Sidebar = ({ currentSection, setCurrentSection }) => {
  return (
    <div className="w-[5rem] sm:w-[2rem] sticky top-1 bg-white min-h-screen py-12 text-center flex gap-y-8 items-center flex-col">
      <div
        className={
          "absolute transition-all ease-in-out duration-1000 bg-dark w-1 translate-y-[3.25rem] sm:translate-y-[1.8rem] h-0 " +
          (currentSection == 2
            ? "!h-[3rem] sm:!h-[2.9rem]"
            : currentSection == 3
            ? "!h-[7rem] sm:!h-[6.5rem]"
            : currentSection == 4
            ? "!h-[11rem] sm:!h-[10rem]"
            : "")
        }
      ></div>
      <a
        href="#section-1"
        className="text-6xl text-dark font-primary font-bold sm:text-4xl"
      >
        D
      </a>
      <a href="#section-2" className="z-10">
        {currentSection >= 2 ? (
          <img className="w-7 sm:w-5" src={DiamondFilled} />
        ) : (
          <img className="w-7 sm:w-5" src={DiamondEmpty} />
        )}
      </a>
      <a href="#section-3" className="z-10">
        {currentSection >= 3 ? (
          <img className="w-7 sm:w-5" src={DiamondFilled} />
        ) : (
          <img className="w-7 sm:w-5" src={DiamondEmpty} />
        )}
      </a>
      <a href="#section-4" className="z-10">
        {currentSection >= 4 ? (
          <img className="w-7 sm:w-5" src={DiamondFilled} />
        ) : (
          <img className="w-7 sm:w-5" src={DiamondEmpty} />
        )}
      </a>
    </div>
  );
};

export default Sidebar;
