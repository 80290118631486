import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useAnimation } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";

const Dialog = ({ index, dialog, dialogSetter, h2, p, img }) => {
  const isMD = useMediaQuery("(max-width:1023px)");
  const isSM = useMediaQuery("(max-width:767px)");
  const [ref, inView] = useInView();
  const controls = useAnimation();
  const dialog_variants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.4,
        delay: (index - 1) / 5,
        width: { duration: 0.1 },
      },
      width:
        dialog == index
          ? isSM
            ? "100%"
            : "50%"
          : isMD && !isSM
          ? "4rem"
          : isSM
          ? "fit-content"
          : "6rem",
    },
    hidden: {
      opacity: 0,
      scale: 0.95,
      width:
        dialog == index
          ? isSM
            ? "100%"
            : "50%"
          : isMD && !isSM
          ? "4rem"
          : isSM
          ? "fit-content"
          : "6rem",
    },
  };
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView, dialog]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={dialog_variants}
      onClick={() => (index != dialog ? dialogSetter(index) : dialogSetter(0))}
      className={
        "border-gold border-2 border-solid sm:w-full flex transition-all ease-in-out duration-500 text-white scrollbar-none overflow-auto flex-nowrap flex-nowrap  justify-between bg-black rounded-xl drop-shadow-lg lg:w-[5rem] lg:h-fit w-[6rem] h-[6rem] md:w-[4rem] md:h-[7.5rem] sm:h-fit "
      }
    >
      <img
        src={img}
        className={
          "bg-white/75 rounded-xl lg:w-[5rem] md:w-[4rem]  md:h-[7.5rem] md:h-fit lg:h-[7rem] w-[6rem] duration-200 ease-in-out transition-colors h-[6rem] cursor-pointer " +
          (dialog == index ? "!bg-white" : "")
        }
      />
      <div className={"py-2 mr-auto"}>
        <h2 className="px-4 font-semibold text-xl md:text-base uppercase sm:text-sm whitespace-nowrap">
          {h2}
        </h2>
        <p
          className={
            "lg:text-xs px-4 text-sm w-[35rem] lg:w-[17rem] md:w-full md:text-xs sm:text-xs sm:w-fit"
          }
        >
          {p}
        </p>
      </div>
    </motion.div>
  );
};

export default Dialog;
