import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { PageNameContext } from "../constants";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";

const AdminAddPromotion = () => {
  const [rooms, setRooms] = useState([]);
  const pageName = useContext(PageNameContext);
  const navigate = useNavigate();
  useEffect(() => {
    pageName.set("Add Promotion");
    document.title = "Chisinau's Diamond Hotel";
    axios
      .get(`${api_url}rooms`)
      .then((res) => {
        setRooms(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function CreatePromotion(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const code = localStorage.getItem("code");
    formData.append("code", code);
    axios
      .post(api_url + "promotion", formData)
      .then((res) => {
        console.log(res.data);
        navigate("/admin/promotions");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <form onSubmit={CreatePromotion} className=" px-8 mt-10">
      {" "}
      <h2 className="text-white font-semibold text-xl">
        Promotion informations
      </h2>
      <div className="grid grid-cols-2 gap-4 mt-2 w-1/2 lg:w-2/3 md:w-full sm:grid-cols-1 sm:w-full">
        <input
          name="name"
          className="admin-input h-fit"
          placeholder="Promotion name"
        />
        <select name="room" className="admin-input h-fit">
          <option value="" disabled selected>
            Room
          </option>
          {rooms.map((room) => (
            <option value={room.id}>{room.name}</option>
          ))}
        </select>
        <textarea
          className="admin-input min-h-[11rem]"
          placeholder="Description"
          name="description"
        ></textarea>
        <div className="flex flex-col w-fit gap-y-2 ml-auto text-sm">
          <Link
            to="/admin/promotions"
            className="admin-alert-button px-8 text-center"
          >
            CANCEL FORM
          </Link>
          <button className="bg-white/60 font-bold py-1  px-8 text-center">
            CREATE PROMOTION
          </button>
        </div>
      </div>
    </form>
  );
};

export default AdminAddPromotion;
