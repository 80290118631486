import React, { useState, useEffect } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { motion } from "framer-motion";
import Section1 from "../sections/Section1";
import Section2 from "../sections/Section2";
import Section3 from "../sections/Section3";
import Section4 from "../sections/Section4";
import Section5 from "../sections/Section5";
import Section6 from "../sections/Section6";
import Section7 from "../sections/Section7";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { api_url } from "../constants";
import ribbon from "../static/images/ribbon.svg";
import useMediaQuery from "../hooks/useMediaQuery";
import PromotionRibbon from "../components/PromotionRibbon";
function Home() {
  const [roomSelected, setRoomSelected] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [code, setCode] = useState("");
  const [promotion, setPromotion] = useState(null);
  useEffect(() => {
    document.title = "Chisinau's Diamond Hotel";

    axios
      .get(api_url + "promotion")
      .then((res) => {
        setPromotion(res.data);
      })
      .catch((err) => {});

    axios
      .get(api_url + "rooms")
      .then((res) => {
        setRooms(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    window.addEventListener("scroll", (e) => {
      const section_1 = document.getElementById("section-1");
      const section_2 = document.getElementById("section-2");
      const section_3 = document.getElementById("section-3");
      const section_4 = document.getElementById("section-4");
      try {
        if (window.scrollY > section_1.offsetTop - 150) {
          setCurrentSection(1);
        }
        if (window.scrollY > section_2.offsetTop - 150) {
          setCurrentSection(2);
        }
        if (window.scrollY > section_3.offsetTop - 150) {
          setCurrentSection(3);
        }
        if (window.scrollY > section_4.offsetTop - 750) {
          setCurrentSection(4);
        }
      } catch (e) {}
    });
  }, []);
  const [currentSection, setCurrentSection] = useState(0);

  function SubmitCode(e) {
    e.preventDefault();
    const code = e.target.code.value;
    setCode(code);
  }

  // code != "scriptify" ? (
  //   <form
  //     onSubmit={SubmitCode}
  //     className="flex flex-col gap-y-4 w-[20rem] text-white absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]"
  //   >
  //     <input name="code" placeholder="Cod Acces" className="" />
  //     <button className="mx-auto bg-black w-full py-2">
  //       Accesează website
  //     </button>
  //   </form>
  // ) :

  return (
    <div className="App flex flex-col">
      {promotion && (
        <div className="md:hidden sm:hidden right-8  lg:right-4 absolute z-20 top-0">
          {" "}
          <PromotionRibbon promotion={promotion} />
        </div>
      )}
      <Section1 promotion={promotion} setRoomSelected={setRoomSelected} />
      <hr className="w-full h-1 bg-black border-black" />
      <div className="flex justify-between items-start">
        <Sidebar
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
        />
        <div className="w-[calc(100%-5rem)] sm:w-[calc(100%-2rem)]">
          <a id="section-2" />
          <Section3
            rooms={rooms}
            roomSelected={roomSelected}
            setRoomSelected={setRoomSelected}
          />

          <p className="px-20 lg:px-12 md:px-8 sm:px-4 pt-16 snap-proximity snap-always snap-start scroll-py-52  text-white font-primary text-left text-2xl mr-auto font-medium">
            Let's make a reservation!{" "}
            {rooms.length > 0 &&
              (roomSelected == -1
                ? "Select a room first"
                : "You selected " + rooms[roomSelected].name + " room.")}
          </p>
          <a id="section-4" />
          {rooms.length > 0 && (
            <Section4
              rooms={rooms}
              indexSelected={roomSelected}
              room={rooms[roomSelected]}
            />
          )}
          <a id="section-3" />
          <Section7 />

          <Section2 />
          <Section5 />

          <Section6 />
          <div className="w-full bg-black">
            <a
              href="https://scriptify.ro"
              className="text-white/60 w-fit flex ml-auto px-4 text-sm py-1"
            >
              Made by Scriptify
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
