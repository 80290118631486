import React, { useEffect } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { api_url } from "../constants";
const RoomSelect = ({ room, roomSelector, index, name, img }) => {
  const [ref, inView] = useInView();
  const controls = useAnimation();
  const pharagraph_variants = {
    visible: {
      opacity: 1,
      translateY: 0,
      transition: { duration: 0.5, delay: index * 0.1 },
    },
    hidden: { opacity: 0, translateY: 10 },
  };
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.a
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={pharagraph_variants}
      href="#section-4"
    >
      <div
        onClick={() => roomSelector(index)}
        className="border-2 cursor-pointer overflow-hidden scrollbar-none border-gold border-solid relative group"
      >
        <img
          className="w-full group-hover:scale-105 object-cover transition-transform ease-in-out duration-500"
          src={api_url.replace("/backend/", "") + img}
        />
        <div className="absolute top-0 items-center text-white left-0 bottom-0 right-0 transition-colors ease-in-out duration-500 group-hover:bg-black/50">
          <span className="top-1/2 group-hover:scale-125 left-1/2 translate-x-[-50%] translate-y-[-50%] transition-all ease-in-out duration-500 absolute text-xl opacity-0 group-hover:opacity-100">
            {room != index ? <RadioButtonUncheckedIcon /> : <TaskAltIcon />}
          </span>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-[20%] bg-black/60 flex items-center">
          <h3 className="sm:text-base w-fit mx-auto uppercase  font-medium font-primary text-white/70 text-xl">
            {name}
          </h3>
        </div>
      </div>
    </motion.a>
  );
};

export default RoomSelect;
