import React from "react";
import diamond from "../static/images/diamond.svg";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import logo from "../static/images/logo.png";
import visa from "../static/images/visa.jpg";
import mastercard from "../static/images/mastercard.svg";
import maestro from "../static/images/maestro.svg";
import { Link } from "react-router-dom";
const Section6 = () => {
  return (
    <section className="px-20 lg:px-12 md:px-8 sm:px-4 pb-2">
      <div>
        <div className="flex mx-auto w-fit mt-16 gap-x-4">
          <img src={diamond} className="w-8" />
          <img src={diamond} className="w-8" />
          <img src={diamond} className="w-8" />
          <img src={diamond} className="w-8" />
        </div>
        <img src={logo} className="w-[20rem] mx-auto mt-4" />
        <div className="flex justify-between mt-8 sm:flex-col items-center sm:gap-y-2 text-sm">
          <div className="flex gap-x-2 sm:mx-auto">
            <PhoneIcon className="text-white" />
            <a href="tel:+37369952598" className=" text-white">
              +373 69952598,
            </a>
            <a href="tel:+36369420555" className=" text-white">
              +373 69420555
            </a>
          </div>
          <div className="bg-white/50 rounded-lg flex gap-x-4 px-4 py-2">
            <img className="h-6" src={visa} />
            <img className="h-6" src={mastercard} />
            <img className="h-6" src={maestro} />
            <Link to="/terms-and-conditions" className="font-semibold ">
              TERMS AND CONDITIONS
            </Link>
          </div>
          <a
            href="mailto:diamondhotelmd@gmail.com"
            className="text-white sm:mx-auto"
          >
            <AlternateEmailIcon />
            diamondhotelmd@gmail.com
          </a>
        </div>
      </div>
    </section>
  );
};

export default Section6;
