import React, { useEffect } from "react";
import diamond from "../static/images/diamond.svg";
import logo from "../static/images/logo.png";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="px-16 lg:px-12 md:px-8 sm:px-4 pt-4 text-white font-primary pb-12">
      <Link
        to="/"
        style={{ width: "fit-content", display: "block", margin: "0 auto" }}
      >
        <img src={logo} className="w-[12rem] mt-4" />
      </Link>
      <section className="mt-8 w-1/2 mx-auto">
        <h1 className="text-2xl font-semibold">TERMS AND CONDITIONS OF USE</h1>
        <h2 className="mt-4 text-xl font-medium">General dispositions</h2>
        <p>
          Welcome to Diamond Hotel! Please read these Terms and Conditions
          carefully, which govern the use of our services. By accessing and
          using our website, www.diamondhotel.md, and our services, you commit
          to fully comply with these terms and conditions.
        </p>
        <p className="mt-2">
          On our website, www.diamondhotel.md, you can make reservations for the
          rooms and facilities offered by Diamond Hotel. Reservations can be
          made at least 30 minutes before the scheduled check-in date and time.
        </p>
        <h2 className="mt-4 text-xl font-medium">
          Protection of personal data
        </h2>
        <h3 className="mt-2 text-lg font-medium">
          Use of Booking Confirmations
        </h3>
        <p>
          Please have the confirmation of the reservation with you at all times
          during your stay at Diamond Hotel. It will be required for access
          control and to ensure access to facilities.
        </p>
        <h3 className="mt-2 text-lg font-medium">
          Confidentiality and Security
        </h3>
        <p>
          It is your responsibility to keep reservation confirmations or other
          authentication information secure. Do not disclose this information to
          third parties. Diamond Hotel and our team will not be responsible for
          the loss or unauthorized use of this information.
        </p>
        <h2 className="mt-4 text-xl font-medium">
          Order registration and payment
        </h2>
        <p>
          For the payment of reservations, we provide the following payment
          methods: Credit/Debit Card (Visa / Mastercard) - This payment method
          is available at all times. After making the reservation, you have 15
          minutes to complete the payment. Otherwise, the reservation will be
          automatically canceled.
        </p>
        <p className="mt-2">
          After completing the payment, you will receive an electronic
          confirmation of the reservation to the provided email address. Please
          carefully review the details and contact us if you notice any
          discrepancies.
        </p>

        <h2 className="mt-4 text-xl font-medium">Terms of delivery</h2>
        <p>
          After receiving confirmation by email that the payment has been
          successful, you can come to the hotel to receive the room for the days
          indicated in the confirmation, respecting all the data provided by the
          client and the hotel's policy.
        </p>
        <p className="mt-2">
          Please have the confirmation of the reservation with you at all times
          during your stay at Diamond Hotel. It will be required for access
          control and to ensure access to facilities.
        </p>
        <h2 className="mt-4 text-xl font-medium">Return Policy</h2>
        <p>
          Refunds for paid amounts are possible under certain conditions:
          <ul className="list-disc ml-4">
            <li>
              If the reservation has not been used and there are less than 14
              days left from the check-in date.
            </li>
            <li>
              Any reservation can be manually canceled using the email
              confirmation. The amount will be refunded to the card used for the
              payment.
            </li>
          </ul>
        </p>
        <h2 className="mt-4 text-xl font-medium">Privacy Policy</h2>
        <p>
          Welcome to Diamond Hotel's Privacy Policy. At Diamond Hotel, we are
          committed to protecting your privacy and ensuring the security of your
          personal information. This Privacy Policy outlines how we collect,
          use, disclose, and protect your data in accordance with the data
          protection laws of the Republic of Moldova.
        </p>
        <h3 className="mt-2 text-lg font-medium">Information We Collect</h3>
        <p>
          We may collect various types of information, including:{" "}
          <ul className="list-disc ml-4">
            <li>
              Personal Information: This includes your name, contact
              information, and payment details when you make a reservation or
              booking with us.
            </li>
            <li>
              Booking Information: Details related to your reservations,
              including dates, preferences, and special requests.
            </li>
            <li>
              Usage Data: Information about how you use our website, including
              IP addresses, browser type, and browsing patterns.
            </li>
          </ul>
        </p>
        <h3 className="mt-2 text-lg font-medium">
          How We Use Your Information
        </h3>
        <p>
          We use your personal information for the following purposes:
          <ul className="list-disc ml-4">
            <li>To process and manage reservations and bookings.</li>
            <li>
              To communicate with you regarding your reservation, inquiries, or
              special requests.
            </li>
            <li>
              To improve our services and website based on user feedback and
              usage data.
            </li>
            <li>
              To comply with legal obligations, such as tax and regulatory
              requirements.
            </li>
          </ul>
        </p>
        <h3 className="mt-2 text-lg font-medium">
          Disclosure of Your Information
        </h3>
        <p>
          We may share your information with:
          <ul className="list-disc ml-4">
            <li>
              Service Providers: Third-party service providers who assist us in
              operating our website and providing our services.
            </li>
            <li>
              Legal Requirements: To comply with legal obligations, such as
              responding to lawful requests from authorities.
            </li>
          </ul>
        </p>
        <h3 className="mt-2 text-lg font-medium">Your Rights</h3>
        <p>
          You have the following rights regarding your personal information:
          <ul className="list-disc ml-4">
            <li>
              Access: You can request access to the personal information we hold
              about you.
            </li>
            <li>
              Rectification: You can request the correction of inaccurate or
              incomplete data.
            </li>
            <li>
              Erasure: You can request the deletion of your personal information
              under certain circumstances.
            </li>
            <li>
              Objection: You can object to the processing of your data for
              certain purposes.
            </li>
          </ul>
        </p>
        <h3 className="mt-2 text-lg font-medium">Data Security</h3>
        <p>
          We implement security measures to protect your data from unauthorized
          access, disclosure, alteration, or destruction. However, no data
          transmission over the internet can be guaranteed to be 100% secure.
        </p>
        <h3 className="mt-2 text-lg font-medium">
          Changes to this Privacy Policy
        </h3>
        <p>
          We may update this Privacy Policy to reflect changes in our practices
          or legal requirements. We will notify you of any significant changes
          through our website.
        </p>
        <h2 className="mt-4 text-xl font-medium">Contacts</h2>
        <p>
          If you have any questions, concerns, or require further assistance
          regarding our terms and conditions, delivery, return policy, or any
          other matter related to our services, please don't hesitate to contact
          us:
          <ul className="list-disc ml-4">
            <li>
              Customer Support Email: diamondhotelmd@gmail.com Customer Support
            </li>
            <li>Phone: +373 69952598</li>
          </ul>
        </p>
        <h3 className="mt-2 text-lg font-medium">Business Information</h3>
        <p>
          <ul className="list-disc ml-4">
            <li>Business Registration: ONEDIAMOND SRL</li>
            <li>Drumul Bacioiului 311/2, 2072 Chisinau, Moldova</li>
          </ul>
        </p>
        <p className="mt-8">
          Thank you for choosing Diamond Hotel. We wish you a pleasant and
          comfortable stay!
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
