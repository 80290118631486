import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { PageNameContext } from "../constants";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import PromotionCard from "../components/PromotionCard";
import LoadingSpinner from "../components/LoadingSpinner";
const AdminPromotions = () => {
  const [promotions, setPromotions] = useState([]);
  const pageName = useContext(PageNameContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    pageName.set("Promotions");
    setLoading(true);
    document.title = "Chisinau's Diamond Hotel";
    const code = localStorage.getItem("code");
    axios
      .get(`${api_url}promotions`, {
        params: {
          code: code,
        },
      })
      .then((res) => {
        setPromotions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="mt-10 px-8">
      <Link
        to="/admin/promotions/add"
        className="bg-white w-fit px-4 py-1.5 font-medium"
      >
        Create a promotion
      </Link>
      <div className="grid mt-4 gap-x-12 gap-y-4 grid-cols-2 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1">
        {promotions &&
          promotions.map((promotion) => (
            <PromotionCard
              key={promotion.id}
              promotion={promotion}
              promotions={promotions}
              setPromotions={setPromotions}
            />
          ))}
      </div>
    </div>
  );
};

export default AdminPromotions;
