import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import close from "../static/images/close.svg";
const EditManualReservation = () => {
  const { id } = useParams();
  const [room, setRoom] = useState({});
  const [toggleAddReservation, setToggleAddReservation] = useState(false);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [reservations, setReservations] = useState([]); // [
  useEffect(() => {
    document.title = "Edit Manual Reservation";
    const code = localStorage.getItem("code");
    axios.get(api_url + "room/" + id).then((res) => {
      setRoom(res.data);
    });
    axios
      .get(api_url + "manual-reservations/" + id, {
        params: {
          code: code,
        },
      })
      .then((res) => {
        setReservations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function CreateManualAppointment(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const code = localStorage.getItem("code");

    if (checkOut < checkIn) {
      alert("Check-out date must be after the check-in date");
      return;
    }
    data.append("room_id", id);
    data.append("code", code);
    axios
      .post(api_url + "manual-reservation/" + id, data)
      .then((res) => {
        const new_reservation = res.data;
        setReservations([...reservations, new_reservation]);
        e.target.reset();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function DeleteReservation(reservation_id) {
    const code = localStorage.getItem("code");
    axios
      .delete(api_url + "manual-reservation/" + id + "/" + reservation_id, {
        params: {
          code: code,
        },
      })
      .then((res) => {
        const new_reservations = reservations.filter(
          (reservation) => reservation.id !== reservation_id
        );
        setReservations(new_reservations);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="px-16 lg:px-12 md:px-8 sm:px-4 text-white font-primary pb-12 mt-12">
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold w-fit text-white mt-2">
          {room.name} ({room.size} m<sup>2</sup>)
        </h2>
        <motion.button
          animate={{
            height: toggleAddReservation ? 0 : "auto",
          }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          onClick={() => setToggleAddReservation(true)}
          className="bg-black px-4 font-medium overflow-auto scrollbar-none"
        >
          Add Manual Reservation
        </motion.button>
      </div>
      <motion.div
        initial={{ height: 0 }}
        animate={{
          height: toggleAddReservation ? "auto" : 0,
        }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
        className="bg-black/50 px-4 mt-2 overflow-auto scrollbar-none"
      >
        <form
          onSubmit={CreateManualAppointment}
          className="py-4 gap-y-2 sm:grid-cols-1 grid md:grid-cols-2 grid-cols-5 gap-x-4"
        >
          <div>
            <label className="text-white font-medium text-sm" for="name">
              Full Name
            </label>
            <input name={"name"} placeholder="John Doe" id="name" />
          </div>
          <div>
            <label className="text-white font-medium text-sm" for="email">
              Email Address
            </label>
            <input
              name={"email"}
              placeholder="example@gmail.com"
              type={"email"}
              id="email"
            />
          </div>
          <div>
            <label className="text-white font-medium text-sm" for="phone">
              Phone Number
            </label>
            <input
              name={"phone"}
              placeholder="+44 1423 396979"
              type={"tel"}
              id="phone"
            />
          </div>
          <div>
            <label className="text-white font-medium text-sm" for="checkin">
              Check-in
            </label>
            <input
              value={checkIn}
              onChange={(e) => setCheckIn(e.target.value)}
              name={"check_in"}
              placeholder="Check-in"
              type={"date"}
              id="checkin"
            />
          </div>
          <div>
            <label className="text-white font-medium text-sm" for="checkout">
              Check-out
            </label>
            <input
              value={checkOut}
              onChange={(e) => setCheckOut(e.target.value)}
              name={"check_out"}
              placeholder="Check-out"
              type={"date"}
              id="checkout"
            />
          </div>
          <button className="bg-white col-span-5 sm:col-span-1 md:col-span-2 w-fit px-8 ml-auto py-1 mt-6 font-semibold text-black">
            Add Manual Reservation
          </button>
        </form>
      </motion.div>
      <div className="flex flex-col gap-y-6 mt-8">
        {reservations.map((reservation, index) => (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
              delay: index * 0.1,
            }}
            className="grid md:grid-cols-2 gap-y-2 sm:grid-cols-1 relative grid-cols-5 bg-black/50 p-4"
          >
            <div>{reservation.name_surname}</div>
            <div>{reservation.email}</div>
            <div>{reservation.phone}</div>
            <div>{reservation.check_in}</div>
            <div>{reservation.check_out}</div>
            <img
              src={close}
              onClick={() => DeleteReservation(reservation.id)}
              className="absolute top-1/2 w-3 cursor-pointer translate-y-[-50%] font-sans right-2 text-2xl font-semibold text-white"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default EditManualReservation;
