import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import header_image from "../static/images/header-image.jpg";
import diamond from "../static/images/diamond.svg";
import PlaceIcon from "@mui/icons-material/Place";
import logo from "../static/images/logo.png";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import PromotionRibbon from "../components/PromotionRibbon";

const Section1 = ({ promotion, setRoomSelected }) => {
  return (
    <section
      id="section-1"
      className="relative h-screen snap-proximity snap-always snap-start scroll-py-52 px:px-20"
    >
      <a id="section-1" />
      <div className="w-full h-full overflow-auto scrollbar-none">
        <motion.img
          initial={{ scale: 1 }}
          animate={{ scale: 1.1 }}
          transition={{ duration: 3, type: "spring" }}
          src={header_image}
          className="object-cover"
        />
      </div>
      <div className="absolute right-0 bottom-0 bg-black/70 top-0 left-0 text-white backdrop-blur-sm saturate-150 flex flex-col">
        <div className="flex mx-auto w-fit mt-16 gap-x-4">
          <img src={diamond} className="w-10" />
          <img src={diamond} className="w-10" />
          <img src={diamond} className="w-10" />
          <img src={diamond} className="w-10" />
        </div>

        <p className="w-fit mt-4 mx-auto text-primary italic text-center text-white/75 text-lg">
          Discover Opulent Tranquility at Diamond Luxury Hotel{" "}
          <br className="xl:hidden" /> Where Elegance Meets Unparalleled Comfort
        </p>
        <img src={logo} className="w-[20rem] mx-auto mt-4" />
        {promotion && (
          <div className="lg:hidden xl:hidden 2xl:hidden mx-auto w-fit md:mt-10 sm:mt-6 ">
            {" "}
            <PromotionRibbon promotion={promotion} />
          </div>
        )}
        <div className="mt-auto pb-16 flex flex-col">
          <p className="w-fit mx-auto mt-2 font-secondary text-sm translate-x-[-1%]">
            <PlaceIcon
              className="text-gold translate-y-[12.5%]"
              style={{ fontSize: "2rem" }}
            />
            Drumul Bacioiului 311/2, 2072 Chisinau, Moldova
          </p>
          <a href="#section-2" className="w-fit flex mx-auto">
            <button className="relative hover:bg-dark transition-colors ease-in-out duration-200 group overflow-y-auto scrollbar-none border-gold mx-auto flex border-4 border-solid px-12 py-4 bg-transparent">
              <span className="group-hover:translate-x-[-5%] transition-transform ease-in-out duration-200 font-primary text-2xl uppercase font-medium ">
                Make a reservation
              </span>
              <span className="right-4 absolute transition-transform inline ease-in-out duration-200 group-hover:translate-y-0 translate-y-[125%]">
                <KeyboardArrowDownIcon style={{ fontSize: "2.5rem" }} />
              </span>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Section1;
