import React from "react";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import ribbon from "../static/images/ribbon.svg";

const PromotionRibbon = ({ promotion }) => {
  return (
    <a href="#section-2">
      <div className="w-64 lg:w-60 md:w-2/3 sm:w-[90%] animate-height h-0 overflow-auto md:mx-auto sm:mx-auto flex flex-col md:flex-row  scrollbar-none cursor-pointer ">
        <div className="bg-white py-5 md:hidden sm:hidden"></div>
        <img
          className="w-full md:w-1/2 sm:w-1/2 sm:mx-auto h-fit overflow-auto border-gold border-solid border-2"
          src={api_url.replace("backend/", "") + promotion.room.img}
        />
        <div className="flex flex-col gap-y-2 p-2 md:w-1/2 text-black bg-white">
          <h2 className="font-semibold md:text-left text-lg text-center text-gold">
            {promotion.name}
          </h2>
          <div className="text-sm flex flex-col font-medium gap-y-1 mb-2">
            {promotion.description &&
              promotion.description
                .split("\n")
                .map((line) => <p className="">{line}</p>)}
          </div>
        </div>
        <img src={ribbon} className="w-full md:hidden sm:hidden" />
      </div>
    </a>
  );
};

export default PromotionRibbon;
