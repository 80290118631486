import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Banner = ({ h, p, img, order }) => {
  const [ref, inView] = useInView();
  const controls = useAnimation();
  const banner_variants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, delay: (order - 1) / 3 },
    },
    hidden: { opacity: 0, scale: 0.95 },
  };
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={banner_variants}
      className="group drop-shadow-xl group ease-in-out duration-500 transition-all"
    >
      <h2 className="text-gold w-fit mx-auto font-primary relative uppercase font-semibold text-xl text-center">
        {h}
      </h2>
      <p className="rounded-t-xl h-0 py-0 group-hover:py-6 group-hover:h-20 w-full lg:group-hover:h-1/2 md:group-hover:h-full md:rounded-xl absolute scrollbar-none overflow-y-auto linear duration-500 transition-all text-sm bg-black px-6 font-secondary text-white text-center">
        {p}
      </p>
      <img
        className="rounded-xl ease-in-out duration-500 transition-all"
        src={img}
      />
    </motion.div>
  );
};

export default Banner;
