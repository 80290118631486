import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../static/images/logo.png";
import axios from "axios";
import { api_url } from "../constants";
import { motion } from "framer-motion";
import expand from "../static/images/expand.svg";
import { PageNameContext } from "../constants";
const AdminReservations = () => {
  const [rooms, setRooms] = useState([]);
  const pageName = useContext(PageNameContext);
  useEffect(() => {
    pageName.set("Reservations");
    document.title = "Chisinau's Diamond Hotel";
    const code = localStorage.getItem("code");
    axios
      .get(api_url + "admin-rooms", {
        params: {
          code: code,
        },
      })
      .then((res) => {
        const res_rooms = res.data;
        res_rooms.map((room) => {
          room.shown_reservations = 5;
        });
        setRooms(res_rooms);
        console.log(res_rooms);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function CancelTransactionHandler(transaction_id, transaction_type) {
    const code = localStorage.getItem("code");
    const response = window.confirm(
      "Are you sure you want to cancel this transaction?"
    );
    if (!response) return;
    axios
      .post(api_url + "cancel-transaction", {
        transaction_id,
        transaction_type,
        code,
      })
      .then((res) => {
        const new_rooms = [...rooms];
        new_rooms.map((room) => {
          const reservations = room.reservations.filter(
            (reservation) => reservation.transaction_id !== transaction_id
          );
          room.reservations = reservations;
        });
        setRooms(new_rooms);
        alert("Transaction canceled successfully");
      })
      .catch((err) => {
        console.log(err);
        alert("Transaction cancel failed. Please try again later.");
      });
  }

  function CancelAndRefundTransactionHandler(transaction_id, transaction_type) {
    const code = localStorage.getItem("code");
    const response = window.confirm(
      "Are you sure you want to cancel and refund this transaction?"
    );
    if (!response) return;
    axios
      .post(api_url + "cancel-and-refund-transaction", {
        transaction_id,
        transaction_type,
        code,
      })
      .then((res) => {
        const new_rooms = [...rooms];
        new_rooms.map((room) => {
          const reservations = room.reservations.filter(
            (reservation) => reservation.transaction_id !== transaction_id
          );
          room.reservations = reservations;
        });
        setRooms(new_rooms);
        alert("Transaction canceled and refunded successfully");
      })
      .catch((err) => {
        alert(
          "Transaction refund failed. Please try again later or check your balance."
        );
      });
  }

  return (
    <div className="px-16 lg:px-12 md:px-8 sm:px-4 pt-4 text-white font-primary pb-12 mt-12">
      <div className="flex flex-col gap-y-6 mt-4 w-full">
        {rooms.length > 0 &&
          rooms.map((room, index) => (
            <motion.div
              className="flex flex-col relative"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: (index + 1) * 0.1 }}
            >
              <div className="flex gap-x-4 items-center">
                <img
                  className="w-20"
                  src={api_url.replace("/backend/", "") + room.img}
                />
                <h2 className="text-lg font-medium">
                  {room.name} ({room.size} m<sup>2</sup>)
                </h2>
              </div>
              {room.reservations.length > 0 && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  transition={{ duration: 0.5, delay: (index + 1) * 0.1 + 0.5 }}
                  className="flex font-secondary text-xs flex-col px-2 bg-black/50 overflow-y-auto scrollbar-none "
                >
                  {room.reservations
                    .slice(0, room.shown_reservations)
                    .map((reservation) => (
                      <div className="grid my-4 gap-x-2 grid-cols-[0.75fr,1fr,0.5fr,5rem,1fr,8rem,8rem,0.5fr,0.5fr] lg:grid-cols-5 md:grid-cols-[1fr,1fr,1fr,5rem] sm:grid-cols-1 sm:gap-y-2 gap-y-1">
                        <div>{reservation.name_surname}</div>
                        <div>{reservation.email}</div>
                        <div>{reservation.phone}</div>
                        <div>{reservation.price} €</div>
                        <div>{reservation.transaction_id}</div>
                        <div>{reservation.check_in}</div>
                        <div>{reservation.check_out}</div>
                        <button
                          className="md:col-span-2 bg-white/50 hover:bg-white transition-colors ease-in-out duration-200 py-0.5 text-black font-semibold"
                          onClick={() =>
                            CancelTransactionHandler(
                              reservation.transaction_id,
                              reservation.transaction_type
                            )
                          }
                        >
                          Cancel
                        </button>
                        <button
                          className="md:col-span-2 bg-white/50 hover:bg-white transition-colors ease-in-out duration-200 py-0.5 text-black font-semibold"
                          onClick={() =>
                            CancelAndRefundTransactionHandler(
                              reservation.transaction_id,
                              reservation.transaction_type
                            )
                          }
                        >
                          Cancel and Refund
                        </button>
                      </div>
                    ))}
                </motion.div>
              )}
              {room.reservations.length > 0 && (
                <img
                  onClick={() => {
                    if (room.reservations.length > room.shown_reservations) {
                      const new_rooms = [...rooms];
                      new_rooms[index].shown_reservations =
                        room.shown_reservations + 5;
                      setRooms(new_rooms);
                    }
                  }}
                  src={expand}
                  className="absolute right-0 bottom-0 text-white w-6 translate-y-1/3 mr-4 rounded-full cursor-pointer bg-black p-2"
                />
              )}
            </motion.div>
          ))}
      </div>
    </div>
  );
};

export default AdminReservations;
