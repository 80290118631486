import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { api_url } from "../constants";
import check from "../static/images/check.svg";
import x from "../static/images/x.svg";
import LoadingSpinner from "../components/LoadingSpinner";
import { motion } from "framer-motion";

const SuccessfulTransaction = () => {
  const [transationInfo, setTransactionInfo] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const transaction_id = localStorage.getItem("transaction_id");
    if (transaction_id == null) {
      navigate("/");
    }
    axios
      .get(`${api_url}reservation`, {
        params: {
          transaction_id: transaction_id,
        },
      })
      .then((res) => {
        setTransactionInfo(res.data);
        setIsLoading(false);
        if(transationInfo.email_sent) return;
        axios
          .post(`${api_url}send-email`, {
            transaction_id,
          })
          .then((res) => {
            console.log(res);
            localStorage.removeItem("transaction_id");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
        setIsLoading(false);
      });
  }, []);
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col w-1/3 lg:w-1/2 md:w-2/3 sm:w-full sm:px-4 mx-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-65%]"
    >
      <img
        src={transationInfo.status !== "OK" ? x : check}
        className="w-16 mx-auto"
      />
      <h1
        className={
          "font-semibold mt-4 uppercase mx-auto w-fit sm:text-sm" +
          (transationInfo.status !== "OK"
            ? " text-[#F20001]"
            : " text-[#40C1AC]")
        }
      >
        {(transationInfo.status == "OK" && "The transaction was successful!") ||
          (transationInfo.status == "REFUNDED" &&
            "The transaction was refunded!") ||
          "The transaction was not successful!"}
      </h1>
      <div className="flex justify-between text-white mt-12">
        <div className="flex flex-col font-semibold sm:text-sm">
          <div>Merchant:</div>
          <div>Website:</div>
          <div>Price:</div>
          <div>Date:</div>
          <div>Transaction ID:</div>
          <div>Description:</div>
        </div>
        <div className="flex flex-col text-right sm:text-sm">
          <div>ONEDIAMOND S.R.L</div>
          <div>diamondhotel.md</div>
          <div>{transationInfo.price} EUR</div>
          <div>{transationInfo.created_at}</div>
          <div>{transationInfo.transaction_id}</div>
          <div>{transationInfo.description}</div>
        </div>
      </div>
      {transationInfo.status === "OK" && (
        <p className="mt-4 text-xs text-white font-medium">
          Check your email for the tickets: {transationInfo.email}
        </p>
      )}
      <Link
        to="/"
        className="bg-[#40C1AC] sm:text-sm w-fit px-4 py-1 font-medium text-white mt-2 uppercase"
      >
        Back to home page
      </Link>
    </motion.div>
  );
};

export default SuccessfulTransaction;
