import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { api_url } from "../constants";
import { PageNameContext } from "../constants";
import LoadingSpinner from "../components/LoadingSpinner";
const AdminRoom = () => {
  const [room, setRoom] = useState({});
  const pageName = useContext(PageNameContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Chisinau's Diamond Hotel";
    setLoading(true);
    axios
      .get(api_url + "room/" + id)
      .then((res) => {
        const res_room = res.data;
        setRoom(res_room);
        pageName.set(res_room.name);
        setLoading(false);

        console.log(res_room);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const navigate = useNavigate();
  function DeleteRoom() {
    // show a confirmation alert
    if (window.confirm("Are you sure you want to delete this room?")) {
      const code = localStorage.getItem("code");

      axios
        .delete(api_url + "room/" + id, { params: { code: code } })
        .then((res) => {
          navigate("/admin/rooms");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className=" px-8 mt-10">
      <div className="flex justify-between items-center sm:flex-col-reverse gap-y-2">
        <h2 className="text-white font-semibold text-xl">Basic Informations</h2>
        <div className="flex w-fit gap-x-4">
          <button
            onClick={DeleteRoom}
            className="admin-alert-button px-8 py-0.5 font-medium"
          >
            Delete room
          </button>
          <Link
            to={"/admin/rooms/" + room.id + "/edit"}
            className="bg-white px-8 py-0.5 font-medium "
          >
            Edit room
          </Link>
        </div>
      </div>
      <div className="mt-2 flex sm:flex-col gap-y-4 w-full gap-x-4 text-white">
        <img
          src={api_url.replace("/backend/", "") + room.img}
          className="w-72 sm:w-full"
        />

        <div className="flex flex-col gap-y-1">
          <div className="flex gap-x-2">
            <span className="font-semibold">Room name:</span>
            {room.name}
          </div>
          <div className="flex gap-x-2">
            <span className="font-semibold">Price:</span>
            {room.price}
          </div>
          <div className="flex gap-x-2">
            <span className="font-semibold">Size:</span>
            <span>
              {room.size} m<sup>2</sup>
            </span>
          </div>
          <div className="flex gap-x-2">
            <span className="font-semibold">Smoking:</span>
            {room.smoking ? "Allowed" : "Not allowed"}
          </div>
        </div>
      </div>
      <div className="mt-4">
        {room.description &&
          room.description
            .split("\n")
            .map((item, index) => <p className="text-white">{item}</p>)}
      </div>
      <div className="grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
        <div className="">
          <h2 className="text-white mt-8 font-semibold text-xl">Features</h2>
          <div className="flex flex-col gap-y-2 font-semibold mt-4">
            {room.features &&
              room.features.map((feature, index) => (
                <div className="bg-white/60 py-1 px-2 w-full flex justify-between group">
                  {feature.name}
                </div>
              ))}
          </div>
        </div>
        <div className="">
          <h2 className="text-white mt-8 font-semibold text-xl">Views</h2>
          <div className="flex flex-col gap-y-2 font-semibold mt-4">
            {room.views &&
              room.views.map((view, index) => (
                <div className="group bg-white/60 py-1 px-2 w-full flex justify-between">
                  {view.name}
                </div>
              ))}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default AdminRoom;
