import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Pharagraph = ({ children, order }) => {
  const [ref, inView] = useInView();
  const controls = useAnimation();
  const pharagraph_variants = {
    visible: {
      opacity: 1,
      translateY: 0,
      transition: { duration: 0.5, delay: (order - 1) / 4 },
    },
    hidden: { opacity: 0, translateY: 10 },
  };
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.p
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={pharagraph_variants}
    >
      {children}
    </motion.p>
  );
};

export default Pharagraph;
