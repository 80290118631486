import React, { useEffect } from "react";
import { useMemo } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import PlaceIcon from "@mui/icons-material/Place";

const Section5 = () => {
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDuaTkVh8-ue6vfJSYHUbaVYIXGxjiao7g",
  });

  const center = useMemo(
    () => ({ lat: 46.965197889245154, lng: 28.85855838208384 }),
    []
  );

  return (
    <section className="px-20 lg:px-12 md:px-8 sm:px-4 pt-16">
      <div className="">
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <div>
            <p className=" text-white sm:text-xs">
              <PlaceIcon
                className="translate-y-[11%]"
                style={{ fontSize: "2rem" }}
              />
              Drumul Bacioiului 311/2, 2072 Chisinau, Moldova
            </p>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "50vh" }}
              center={center}
              zoom={16}
            >
              <MarkerF position={center} />
            </GoogleMap>
          </div>
        )}
      </div>
    </section>
  );
};

export default Section5;
