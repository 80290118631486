import React, { useEffect, useState, useContext } from "react";
import { Outlet } from "react-router-dom";
import logo from "../static/images/logo.png";
import burger from "../static/images/burger.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { PageNameContext, api_url } from "../constants";
import { motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";
import burger_white from "../static/images/burger_white.svg";
import reservations from "../static/images/reservations.svg";
import manual_reservations from "../static/images/manual-reservations.svg";
import rooms from "../static/images/rooms.svg";
import promotions from "../static/images/promotions.svg";
const AdminLayout = () => {
  const navigate = useNavigate();
  const [menuExpanded, setMenuExpanded] = useState(true);
  const isSM = useMediaQuery("(max-width:640px)");
  const pageName = useContext(PageNameContext);
  const [code, setCode] = useState("");
  const [access_granted, setAccessGranted] = useState(false);
  useEffect(() => {
    const code = localStorage.getItem("code");
    if (code) {
      axios.post(api_url + "verify-code", { code }).then((res) => {
        console.log(res.data);
        if (res.data.message === "OK") {
          setAccessGranted(true);
        }
      });
    }
  }, []);

  function GetData(e) {
    e.preventDefault();
    axios.post(api_url + "verify-code", { code }).then((res) => {
      console.log(res.data);
      if (res.data.message === "OK") {
        setAccessGranted(true);
        localStorage.setItem("code", code);
      }
    });
  }

  return !access_granted ? (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="flex flex-col gap-y-4 w-[20rem] text-white absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]"
    >
      <input
        type="password"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        name="code"
        placeholder="Admin Password"
        className=""
      />
      <button className="mx-auto bg-black w-full py-2" onClick={GetData}>
        Enter Admin Panel
      </button>
    </form>
  ) : (
    <div className="flex">
      <motion.div
        initial={{ width: isSM ? 0 : "14rem" }}
        animate={{
          width:
            menuExpanded && !isSM ? "14rem" : isSM && menuExpanded ? "100%" : 0,
        }}
        transition={{ duration: 0.3 }}
        className="py-8 relative min-h-[100vh] bg-black overflow-x-auto scrollbar-none"
      >
        <motion.img
          initial={{ opacity: 0, x: -10, display: "none" }}
          animate={{
            opacity: menuExpanded && isSM ? 1 : 0,
            x: menuExpanded && isSM ? 0 : -10,
            display: menuExpanded && isSM ? "block" : "none",
          }}
          transition={{ duration: 0.3, display: { delay: 0.3 } }}
          src={burger_white}
          className="w-4 cursor-pointer hover:scale-105 ease-in-out duration-300  transition-transform absolute top-4 right-4"
          onClick={() => setMenuExpanded(!menuExpanded)}
        />
        <img className="w-28 mx-auto" src={logo} />
        <ul className="text-white flex flex-col mt-6 ">
          <Link
            to={"/admin/rooms"}
            className={
              "w-fit py-2 px-4 text-base whitespace-nowrap	 flex items-center gap-x-3" +
              (pageName.get.includes("rooms")
                ? " font-semibold"
                : " font-medium")
            }
          >
            <img src={rooms} className="w-5" /> Rooms
          </Link>
          <Link
            to={"/admin/reservations"}
            className={
              "w-fit py-2 px-4 text-base whitespace-nowrap	 flex items-center gap-x-3" +
              (pageName.get.includes("reservations")
                ? " font-semibold"
                : " font-medium")
            }
          >
            <img src={reservations} className="w-5" /> Reservations
          </Link>
          <Link
            to={"/admin/manual-reservations"}
            className={
              "w-fit py-2 px-4 text-base whitespace-nowrap	 flex items-center gap-x-3 " +
              (pageName.get.includes("manual-reservations")
                ? "font-semibold"
                : "font-medium")
            }
          >
            <img src={manual_reservations} className="w-5" /> Manual
            Reservations
          </Link>
          <Link
            to={"/admin/promotions"}
            className={
              "w-fit py-2 px-4 text-base whitespace-nowrap	 flex items-center gap-x-3 " +
              (pageName.get.includes("promotions")
                ? "font-semibold"
                : "font-medium")
            }
          >
            <img src={promotions} className="w-5" /> Promotions
          </Link>
        </ul>
      </motion.div>
      <motion.div
        initial={{ width: "calc(100% - 14rem)" }}
        animate={{
          width:
            menuExpanded && !isSM
              ? "calc(100% - 14rem)"
              : isSM && menuExpanded
              ? "0"
              : "100%",
        }}
        transition={{ duration: 0.3 }}
        className="min-h-[100vh] overflow-x-auto scrollbar-none"
      >
        <div className="w-full whitespace-nowrap gap-x-4 h-10 bg-white flex items-center px-6 text-black uppercase font-semibold text-lg">
          <img
            src={burger}
            className="w-4 cursor-pointer hover:scale-105 ease-in-out duration-300 transition-transform"
            onClick={() => setMenuExpanded(!menuExpanded)}
          />
          {pageName.get}
        </div>
        <Outlet code={code} />
      </motion.div>
    </div>
  );
};

export default AdminLayout;
